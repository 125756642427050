'use client';
import React from 'react';

import * as Styled from './styled';
import { IRepository } from '@/types';
import Tag from '@/ui/Tag';
import * as Icon from '@/ui/Icon';
import { Row } from '@/ui/primatives';
import { formatDate } from '@/lib/dateTime';
import Link from 'next/link';
import { RepositoryDataTypesChart } from '@/components/Repository/RepositoryDataTypes';
import RepositoryCategories from '@/components/Repository/RepositoryCategories';

const CardHeader: React.FC<{ repository: IRepository }> = ({ repository }) => (
  <Styled.CardHeader>
    <h6 className="block">
      <Link
        className="hover-link"
        href={`/${repository.namespace}`}
        data-ph-capture-attribute-resource-type="namespace"
      >
        {repository.namespace}
      </Link>
      /
      <Link
        className="hover-link"
        href={`/${repository.namespace}/${repository.name}`}
        data-ph-capture-attribute-resource-type="respository"
      >
        {repository.name}
      </Link>
    </h6>
    <p>{repository.description}</p>
  </Styled.CardHeader>
);
const CardFooter: React.FC<{ repository: IRepository }> = ({ repository }) => (
  <Styled.CardFooter>
    {repository.latest_commit ? (
      <Row gap={5} alignItems="center" justifyContent="flex-start">
        <Icon.Clock size="1em" />
        <div
          className="timestamp"
          suppressHydrationWarning={true}
        >{`Updated: ${formatDate(repository.latest_commit.timestamp)}`}</div>
      </Row>
    ) : (
      <div className="timestamp" onClick={() => console.log('repo', repository)}>
        Empty Repository
      </div>
    )}
    <Row gap={0} alignItems="center" justifyContent="flex-end">
      <Tag small variant="transparent" title="Total downloads">
        <Icon.Download size="12" />
        <span>{repository.metrics.clone_count}</span>
      </Tag>
      <Tag small variant="transparent" title="Total stars">
        <Icon.Star size="12" />
        <span>{repository.star_count}</span>
      </Tag>
    </Row>
  </Styled.CardFooter>
);

const RepositoryCard: React.FC<{ repository: IRepository }> = ({ repository }) => {
  return (
    <Styled.RepositoryCard>
      <CardHeader repository={repository} />
      <Styled.CardBody>
        <RepositoryDataTypesChart repository={repository} />
        <RepositoryCategories repository={repository} />
      </Styled.CardBody>
      <CardFooter repository={repository} />
    </Styled.RepositoryCard>
  );
};

export default RepositoryCard;
