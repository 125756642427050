'use client';
import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const FeaturedContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  padding-top: 50px;

  & > * {
    flex: 1;
    display: contents;
  }

  @media (max-width: ${breakPoints.smallDesktop}) {
    padding: 50px 20px 0;
  }
  @media (max-width: ${breakPoints.mobile}) {
    h2 {
      font-size: 36px;
    }
    padding: 20px 10px 0;
    gap: 20px;
  }
`;

export const FeaturedContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid ${colors.secondary.lightGray3};
  border-radius: 10px;
  max-width: 330px;
  padding-bottom: 20px;
  background: ${colors.primary.white};

  img {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 185px;
    object-fit: contain;
    background: white;
    border-bottom: 1px solid ${colors.secondary.lightGray3};
  }

  p {
    height: 5lh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h5 {
    padding: 0 20px;
  }
  & > * {
    flex: 1;
    padding: 0 20px;

    &:first-child,
    &:last-child {
      flex: 0;
    }
    &:first-child {
      padding: 0px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.secondary.lightGray3};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  h6 {
    color: ${colors.primary.white};
    font-size: 1.2rem;
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${colors.semantic.information.light};
  color: ${colors.semantic.information.dark};
  font-size: 0.8rem;
  padding: 5px 10px;
`;
